.export-preview-text {
    background-color: #EEEEEE;
    white-space: pre-wrap;
    max-width: 80em;
    max-height: 20em;
    font-size: xx-small;
    overflow-x: auto;
}

.settings-summary {
    color: gray;
    font-size: x-small;
}