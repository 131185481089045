.active {
    stroke: none;
    fill: orange;
}

.inactive {
    stroke:none;
    fill: none;
}

.circle-active-overlay {
    stroke: transparent;
    stroke-width: 2px;
    fill: transparent;
    cursor: pointer; 
}

.circle-active-overlay:hover {
    fill: #8888FF33;
}