.template-preview-text { font-size: x-small; line-height: 1em; text-align: left; }
.template-button-container { 
    display: flex;
    flex-direction: horizontal;
    background-color: #777777;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 10em;
}
.template-button-tile { 
    background-color: #777777;
    padding: 6px;    
}
.template-button {
    background-color: #777777;
    border: none;
}
.template-button:hover {
    box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.49);
    position: relative;
    top:-1px;
    left:-1px;
}
.template-selected {
    background-color: #999999;
    position: relative;
    top:-1px;
    left:-1px;
    box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.6);
}
.template-highlight { 
    background-color: lightgrey;
}

pre {
    font-family: "Noto Sans Mono", source-code-pro, Menlo, Monaco, Consolas, 'Droid Sans Mono', 'Courier New', monospace;
}