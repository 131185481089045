/* .viewbox { 
    user-select: none; 
    width: 100vw;
    height: 100vh;
    border-style: solid; 
    border-width: 1px;
    border-color: black; 
    padding: 0;
    margin: 0;
    cursor: pointer;
} */

/* .d3-editor {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: magenta; 
} */

/* .app-react-editor-group {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: yellow; 
} */

.d3svgContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    background-color: cyan;
}

.d3svg {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: content;
    background-color: white;
    max-width: unset;
    user-select: none;
    touch-action: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

g {
    /** See also http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/ for monospace sizing, https://meta.stackexchange.com/questions/33945/not-enforcing-fixed-width-font-family */
    font-family: "Noto Sans Mono", "Consolas", "Monaco", "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
    font-size: 1em;
}

.handle path {
    fill: #FF000088;
    stroke: #00000088;
    stroke-width: 1px;
}

.cellBackground {
    filter: drop-shadow(1em 3px 2px rgba(0, 0, 0, .7));
    fill: white;
}

.cursorEditable {
    width: 1em;
    height: 1em;
    margin: 1em;
    padding: 1em;
    color: green;
    background-color: blue;
}

[contenteditable]:focus {
    outline: 0px solid transparent;
}