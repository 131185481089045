.selectBox { 
    stroke: blue;
    fill: #0000FF11;
}


.lassoCellSide { 
    stroke: blue;
}

.lassoCellFill { 
    fill: #0000FF11;
}
