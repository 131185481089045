.vBox { 
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.hBox { 
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
}

.optionsOverlay {
    position: absolute;
    place-self: end;
    padding: 1em;
}

.pickerBox {
    display: flex;
    flex-direction: column;
    flex: 0;
    background-color: #777777;
}

.d3Editor {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}