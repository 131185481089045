.button-connector {
    fill: none;
    stroke: blue;
}

.button-row-background {
    fill: #bbbbdd99;
    stroke: #00004433;
    stroke-width: 0.5px;
}
